import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from "gatsby"


import Glide from 'react-glidejs';

const ImageGallery = (props) => {

	const gliderRef = useRef(null);

	const data = useStaticQuery(
		graphql`query {
	images: allFile(filter: {sourceInstanceName: {eq: "logotipos"}}, sort: {order: ASC, fields: relativePath}) {
		edges {
		  node {
			relativePath
			base
		  }
		}
	}
}
`)


	const galleryImages = data.images.edges.filter(edge =>
		edge.node.relativePath.startsWith(props.foldername)
	)
	
	return (
		<Glide
			ref={gliderRef}
			throttle={0}
			type="slider"
			perView={1}
			slideClassName="slider__frame"
			focusAt="center"
			gap={0}
		>

			{galleryImages.map((image, i) => (
				<img key={i} className='slider__image' src={ `/logotipos/${image.node.relativePath}` } style={{ backgroundColor: i == 0 ? props.bgColor : null }} loading="lazy"></img>
			))}
		</Glide>
	)
}


ImageGallery.propTypes = {
	foldername: PropTypes.string,
	bgColor: PropTypes.string
};

export default ImageGallery;