import React, { useRef, createRef, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Intro from '../components/logotipos/intro'
import Icons from '../components/icons'
import PropTypes from 'prop-types';
import ImageGallery from '../components/logotipos/image-gallery';
import Cursor from '../components/cursor'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'


function PortfolioIndex({ data }) {

	const [modalVisible, setIsShowing] = useState(false);

	function updateStateModal() {
		setIsShowing(!modalVisible);
	}

	const containerRef = useRef(null)

	const posts = [...data.withDate.edges, ...data.withoutDate.edges];
	const refs = useRef([]);
	refs.current = posts.map((element, i) => refs.current[i] ?? createRef());


	return (
		<>
			<LocomotiveScrollProvider options={{ smooth: true, tablet: { smooth: true }, smartphone: { smooth: false }}} watch={[]} containerRef={containerRef}>
				<main id="top" data-scroll-container ref={containerRef}>
					<Layout type="others" left="logotipos" visible={modalVisible} showreelModal={updateStateModal} >
						<Intro />
						<section id="logotipos" className="p-relative max-width">

							<div className="row">
								<div className="col-xs-12">

									{posts.map(({ node }, i) => {

										let bgColor = "#" + node.colorPicker;

										return (
											<div key={node.id} ref={refs.current[i]} id={node.folder} className="portfolio-item animated" data-scroll>

												<div className="p-relative portfolio-item--hover">
													<span className="number">#{('000' + (i + 1)).slice(-3)}</span>
													<ImageGallery foldername={node.folder} bgColor={bgColor} />
													<span className="location">{node.location}</span>
												</div>
												<div className="item-details p-relative">
													<div className="item-title">{node.title}</div>
													<div className="item-description">{node.description}</div>
													<div className="item-line"></div>
												</div>
											</div>
										)
									})}

								
								</div>
							</div>
						</section>
						<Icons />
					</Layout>
				</main>
			</LocomotiveScrollProvider>
			<Cursor />
		</>
	)
}

PortfolioIndex.propTypes = {
	data: PropTypes.object.isRequired,
};
export default PortfolioIndex


export const pageQuery = graphql`
query PortfolioIndexQuery {
	withDate:allContentfulPortfolio(
	 filter: { order: { ne: null } },
	  sort: {fields: order, order: DESC}) {
		  edges {
			node {
			  title
			  id
			  location
			  number
			  colorPicker
			  description
			  folder
			  order
			}
		  }
	}
	withoutDate:allContentfulPortfolio(
	   filter: { order: { eq: null } },
	  sort: {fields: order, order: DESC}) {
		  edges {
			node {
			  title
			  id
			  location
			  number
			  colorPicker
			  description
			  folder
			  order
			}
		  }
	}
  }
		`
